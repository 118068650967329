import * as React from "react";
import "./base.css";

const Layout = ({ children }) => {
  return (
    <>
      <div className="bg-gray-50 min-h-screen">
        <div className="container mx-auto">{children}</div>
      </div>
    </>
  );
};

export default Layout;
